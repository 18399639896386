/* You can add global styles to this file, and also import other style files */
* {
  /*font-family: 'Roboto', sans-serif !important;*/
  font-family: "DM Sans", sans-serif !important;
}

.bg-dark-green {
  background: #1F3F49 !important;
}

.bg-dark-green2 {
  background: #3BB073 !important;
}

.text-bg-dark-green {
  color: #1F3F49 !important;
}

.text-light-green {
  color: rgb(75, 206, 199) !important;
}

.text-dashboard-profile {
  color: rgb(26, 24, 21) !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 26.04px !important;
}

.text-dashboard-profile-success {
  color: rgb(0, 157, 18) !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 20.83px !important;
}

.text-dashboard-profile-text-muted {
  color: rgb(102, 102, 102) !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}

.text-dashboard {
  color: #194F5A !important;
  font-size: 32px !important;
  font-weight: 700 !important;
  line-height: 36px !important;
}

.text-dashboard-muted {
  color: rgb(102, 102, 102) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}

:root {
  --button-primary-bg: rgba(34, 85, 83, 1);
  --button-login-bg: #262E37;
  --bs-nav-tabs-link-active-bg: #18725d;
  --white: #ffffff;
}

/*
.spinner-border {
    display: block;
    position: fixed;
    top: calc(50% - (58px / 2));  
    right: calc(50% - (58px / 2));
    color: #1F3F49;
}
.bd{
    position:absolute !important;
    top:0 !important;
    width: 100vw !important;
    height: 100vh !important;
    z-index: 999 !important;
    background-color: rgb(0,0,0,0.2) !important;
}
*/
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 99999999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.4), rgba(0, 0, 0, 0.4));
  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.4), rgba(0, 0, 0, 0.4));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*
.btn-primary {
    background-color: #488A99 !important;
    border: 1px solid #488A99 ;
}
*/
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--button-primary-bg) !important;
  --bs-btn-border-color: var(--button-primary-bg) !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-nav-tabs-link-active-bg) !important;
  --bs-btn-hover-border-color: var(--bs-nav-tabs-link-active-bg) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-nav-tabs-link-active-bg) !important;
  --bs-btn-active-border-color: var(--bs-nav-tabs-link-active-bg) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--button-primary-bg) !important;
  --bs-btn-disabled-border-color: var(--button-primary-bg) !important;
}

.btn-outline-primary {
  --bs-btn-color: var(--button-primary-bg) !important;
  --bs-btn-bg: none !important;
  --bs-btn-border-color: var(--button-primary-bg) !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-nav-tabs-link-active-bg) !important;
  --bs-btn-hover-border-color: var(--bs-nav-tabs-link-active-bg) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-nav-tabs-link-active-bg) !important;
  --bs-btn-active-border-color: var(--bs-nav-tabs-link-active-bg) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--button-primary-bg) !important;
  --bs-btn-disabled-border-color: var(--button-primary-bg) !important;
}

.btn-login {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--button-login-bg) !important;
  --bs-btn-border-color: var(--button-login-bg) !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-nav-tabs-link-active-bg) !important;
  --bs-btn-hover-border-color: var(--bs-nav-tabs-link-active-bg) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-nav-tabs-link-active-bg) !important;
  --bs-btn-active-border-color: var(--bs-nav-tabs-link-active-bg) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--button-login-bg) !important;
  --bs-btn-disabled-border-color: var(--button-login-bg) !important;
}

.btn-outline-login {
  --bs-btn-color: #fff;
  --bs-btn-border-color: var(--button-login-bg) !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-nav-tabs-link-active-bg) !important;
  --bs-btn-hover-border-color: var(--bs-nav-tabs-link-active-bg) !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-nav-tabs-link-active-bg) !important;
  --bs-btn-active-border-color: var(--bs-nav-tabs-link-active-bg) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--button-login-bg) !important;
  --bs-btn-disabled-border-color: var(--button-login-bg) !important;
}

.col.active {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

html, body {
  height: auto;
}

body {
  margin: 0;
  background-color: rgba(240, 240, 240, 0.5) !important;
  overflow-x: hidden;
}

.bg {
  height: auto;
  min-height: 50%;
  min-width: 50%;
  background: url("../src/assets/images/login-vector.svg") no-repeat center center fixed, linear-gradient(180deg, rgb(88, 172, 128), rgb(49, 121, 108));
  background-size: cover;
}

.bg-login {
  height: auto;
  width: auto;
  background: url("../src/assets/images/ucheck_bg.png") no-repeat 86px 0 fixed, rgb(20, 52, 59);
  background-size: cover;
}

.view-bg {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(109, 205, 200, 0.2) 72.87%), #F5F5F5;
  background-size: cover;
}

.view-bg2 {
  height: auto;
  background: linear-gradient(180deg, rgba(109, 205, 200, 0.2) 0%, rgba(109, 205, 200, 0.2) 100%), #F5F5F5;
  background-size: cover;
}

small {
  font-size: 8pt !important;
}

span.text-success {
  color: #225553 !important;
}

/*CUSTOM FILE INPUT*/
.custom-file-bulk {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 8px;
  width: 100%;
}

.custom-file-bulk-invalid {
  border: 1px solid red;
}

.custom-file-bulk::-webkit-file-upload-button {
  display: none;
}

.custom-file-bulk::before {
  margin: 5px 8px;
  content: "Choose File";
  color: rgb(34, 126, 122) !important;
  display: inline-block;
  background: transparent;
  border: 1px solid rgb(34, 126, 122) !important;
  border-radius: 8px;
  padding: 5px 24px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

.custom-file-input::-webkit-file-upload-button {
  display: none;
}

.custom-file-input::before {
  margin-right: 10px;
  content: "choose file/s";
  color: rgb(34, 126, 122) !important;
  display: inline-block;
  background: transparent;
  border: 1px solid rgb(34, 126, 122) !important;
  border-radius: 10px;
  padding: 10px 13px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

.custom-file-input:hover::before {
  border-color: black;
}

.custom-file-input:active {
  outline: 0;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

input::placeholder {
  font: 0.8rem/3 "DM Sans", sans-serif !important;
  color: #4B4B4B !important;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font: 0.8rem/3 "DM Sans", sans-serif !important;
  color: #4B4B4B !important;
}

::-ms-input-placeholder { /* Microsoft Edge */
  font: 0.6rem/3 "DM Sans", sans-serif !important;
  color: #4B4B4B !important;
}

table th, td {
  font-size: 14px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-400 {
  font-weight: 400 !important;
}

.font-700 {
  font-weight: 700 !important;
}

/*CUSTOM FILE INPUT*/
@media screen and (max-width: 1800px) {
  .text-dashboard-muted {
    font-size: 70% !important;
  }
}
@media screen and (max-width: 1600px) {
  .text-dashboard-muted {
    font-size: 60% !important;
  }
}
.table-responsive {
  overflow-y: hidden !important;
}

.table-responsive:hover {
  overflow-y: scroll !important;
}

.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(183, 183, 183, 0.3);
  background-color: transparent;
}

.table-responsive::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(183, 183, 183, 0.3);
}

.full-width {
  width: 100% !important;
  border: 0 !important;
  display: absolute !important;
}

ngx-datepicker div input {
  background-color: transparent !important;
  background: url("assets/images/datepicker.svg") no-repeat scroll 90% 50% !important;
  width: 100% !important;
  max-width: 100% !important;
}

.selected {
  background-color: rgba(33, 34, 33, 0.1); /* Change this to the desired color for selected items */
}

.file-text-truncate {
  display: flex;
}

.v2-color-side-top {
  background: linear-gradient(to bottom, #31796C 100%, #31796C 100%) !important;
}

.v2-color-side-middle {
  background: linear-gradient(to bottom, #31796C 0%, #14343B 100%) !important;
}

.v2-color-side-bottom {
  background: linear-gradient(to bottom, #14343B 100%, #14343B 100%) !important;
}

input[type=checkbox]:checked {
  background: #225553;
}

.collapse-success {
  border-radius: 8px;
  background: rgba(204, 244, 237, 0.5);
}

.custom-card {
  border: none !important;
}

.custom-bg {
  background-color: #ffffff;
}

.mt-custom {
  margin-top: 1.2in !important;
}

.fs-custom {
  font-size: 12px !important;
}

.nav-pills > .nav-item > .nav-link {
  height: 54px !important;
}

.nav-pills > .nav-item > .nav-link {
  width: 306px;
  height: 54px;
  padding: 15px 20px 15px 20px;
  gap: 617px;
  border-radius: 8px 8px 0px 0px;
  background-color: rgb(240, 240, 240);
  color: rgb(49, 121, 103) !important;
}

.nav-link.active {
  width: 306px !important;
  padding-top: 20px !important;
  height: 54px !important;
  gap: 617px;
  border-radius: 8px 8px 0px 0px;
  opacity: 0px;
  background-color: rgba(204, 244, 237, 0.5) !important; /* Change this to the desired color for selected items */
}

.nav-pills-custom > .nav-item > .nav-link {
  height: 50px !important;
  border: none;
}

.nav-pills-custom > .nav-item > .nav-link {
  width: 200px !important;
  height: 50px !important;
  padding: 10px 15px 10px 15px !important;
  gap: 617px !important;
  border-radius: 4px 4px 4px 4px !important;
  background-color: transparent !important;
  color: rgb(49, 121, 103);
}

.nav-pills-custom > .nav-item > .nav-link.active {
  width: 200px !important;
  height: 50px !important;
  padding: 10px 15px 10px 15px !important;
  gap: 617px;
  border-radius: 4px 4px 4px 4px !important;
  opacity: 0px;
  background-color: rgb(221, 237, 244) !important; /* Change this to the desired color for selected items */
}

.font-custom1 {
  font-size: 55px !important;
  font-family: DM Sans, sans-serif;
}

.font-custom2 {
  font-size: 15px !important;
  font-family: DM Sans, sans-serif;
}

/*--------------------------------------fixed top--------------------------------------*/
/*@media only screen and (max-width: 990px) {
    .side-label {
        display: none;
    }
}*/
@media only screen and (max-width: 990px) and (min-width: 769px) {
  .side-label {
    display: none;
  }
  .side_logo {
    text-align: center;
  }
  .view_bg_body {
    padding: 0 0.5in 0.5in 0.5in;
  }
}
@media only screen and (max-width: 767px) {
  .navbar_float_right {
    overflow: hidden !important;
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    width: 100% !important;
    z-index: 1 !important;
  }
  .navbar_float_left {
    overflow-y: hidden !important;
    display: none;
  }
  .router_body {
    margin-top: 1in;
  }
  .view_bg_body {
    margin: 1in 0.5in;
  }
}
@media only screen and (min-width: 768px) {
  .navbar_float_right {
    overflow: hidden !important;
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    width: 82% !important;
    z-index: 1;
  }
  .navbar_float_left {
    overflow-y: hidden !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 18% !important;
    height: 100% !important;
    z-index: 1;
  }
  .router_body {
    margin-top: 1in;
    margin-left: 19%;
  }
  .view_bg_body {
    margin-top: 1in;
    margin-left: 19%;
  }
}
.navbar_float_left:hover {
  overflow-y: scroll !important;
}

.navbar_float_left::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(183, 183, 183, 0.3);
  background-color: transparent;
}

.navbar_float_left::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.navbar_float_left::-webkit-scrollbar-thumb {
  background-color: rgba(183, 183, 183, 0.3);
}

.view-bg:hover {
  overflow-y: scroll !important;
}

/*--------------------------------------fixed top--------------------------------------*/
/*--------------------------------------module header--------------------------------------*/
.custom-module-header {
  height: 90px;
  top: 190px;
  left: 430px;
  gap: 0px;
  border-radius: 18px 18px 0px 0px;
  opacity: 0px;
  background: rgb(204, 244, 237);
}

.custom-module-header .custom-module-header-text {
  font-size: 20px !important;
  font-weight: 700 !important;
}

/*--------------------------------------module header--------------------------------------*/
/*--------------------------------------bottom footer--------------------------------------*/
.valign {
  display: table;
  width: 100%;
  height: 15%;
}

.valign > div {
  display: table-cell;
  width: 100%;
  height: 15%;
}

.valign.bottom > div {
  vertical-align: bottom;
}

/*--------------------------------------bottom footer--------------------------------------*/
.invalid-input-group {
  border-left: 1px solid #dc3545 !important;
  border-top: 1px solid #dc3545 !important;
  border-bottom: 1px solid #dc3545 !important;
}

.info-img {
  background-image: url("./assets/images/question.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  width: 15px;
  height: 15px;
}

#range1 {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 16px;
}

#range1::-webkit-slider-runnable-track {
  height: 15px;
  background: #ccc;
  border-radius: 16px;
}

#range1::-moz-range-track {
  height: 15px;
  background: #ccc;
  border-radius: 16px;
}

#range1::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #f50;
  box-shadow: -407px 0 0 400px #f50;
}

#range1::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #f50;
  box-shadow: -407px 0 0 400px #f50;
}

/* range 2 */
.range-input {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 6px;
  background: #ccc;
}

.range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: #f50;
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
}

.range-input::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #f50;
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
}

.range-input::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 10px rgba(255, 85, 0, 0.1);
}

.range-input:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
}

.range-input:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
}

.range-input::-moz-range-thumb:hover {
  box-shadow: 0 0 0 10px rgba(255, 85, 0, 0.1);
}

.range-input:active::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
}

.range-input:focus::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
}

.value2, .value3, .value4 {
  font-size: 26px;
  width: 50px;
  text-align: center;
}

/* range 3 */
#range3 {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 20px;
  height: 6px;
  background: #ccc;
}

#range3::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 30px;
  width: 30px;
  background: transparent;
  background-image: url("https://ibaslogic.github.io/hosted-assets/smile.png");
  background-size: cover;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
  transform: rotateZ(var(--thumb-rotate, 0deg));
}

/* Thumb: Firefox */
#range3::-moz-range-thumb {
  height: 30px;
  width: 30px;
  background: transparent;
  background-image: url("https://ibaslogic.github.io/hosted-assets/smile.png");
  background-size: cover;
  border: none;
  border-radius: 50%;
  transform: rotateZ(var(--thumb-rotate, 0deg));
  transition: 0.2s ease-in-out;
}

#range3::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 10px rgba(255, 85, 0, 0.1);
}

#range3:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
}

#range3:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
}

#range3::-moz-range-thumb:hover {
  box-shadow: 0 0 0 10px rgba(255, 85, 0, 0.1);
}

#range3:active::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
}

#range3:focus::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(255, 85, 0, 0.2);
}

/* range 3 */
.range-slider {
  flex: 1;
}

.sliderticks {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.sliderticks span {
  display: flex;
  justify-content: center;
  width: 1px;
  height: 10px;
  background: #D3D3D3;
  line-height: 40px;
}

/*=============
Aesthetics
=========================*/
body {
  font-family: system-ui;
}

h1 {
  color: #4B4949;
  text-align: center;
}

.wrapper {
  color: #4B4949;
  background: #f50;
  max-width: 600px;
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.content {
  width: 100%;
  max-width: 500px;
  height: 80%;
  background: #fff;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.range {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.popover-content {
  white-space: pre-line;
}

.otp-invalid {
  border: 1px solid #FF4343 !important;
}

.auditlog-field {
  width: 85%;
}
.auditlog-field div {
  background: #FFFFFF;
}
.auditlog-field .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: #FFFFFF;
}
.auditlog-field .mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: #225553;
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #225553 !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #225553 !important;
}